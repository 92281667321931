.nav {
  display: flex;
  height: 70px;
  align-items: center;
  padding: 0 20px;

  .nav_title {
    color: #000000;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0.21px;
    line-height: 24px;
    width: 100%;
    text-align: left;
  }

  .nav_filter {
    cursor: pointer;
    height: 25px;
    width: 24px;
    &:hover {
      opacity: 0.7;
    }
  }
}
