.header {
  height: 57px;
  background: #000000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  position: relative;

  .reset-filters {
    color: #fbc310;
    font-size: 12px;
    letter-spacing: 0.14px;
    line-height: 14px;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
    z-index: 1;
  }

  .header_profile-photo-container {
    z-index: 1;
    border-radius: 50%;
    border: solid 2px #fbc310;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: #fbc310;
  }

  .header-size {
    height: 40px;
    width: 40px;
    z-index: 1;
    border-radius: 50%;
  }

  .header_profile-photo {
    overflow: hidden;
    height: 30px;
    width: 30px;
    object-fit: cover;
    border-radius: 50%;
  }
  .header_container_logo {
    width: 100%;
    position: absolute;
    left: 0;
  }
  .header_logo {
    width: 120px;
  }
  .header_strava_logo {
    height: 31px;
    width: 30px;
  }
  .add-button-container {
    position: relative;
  }
  .add-button {
    position: absolute;
    height: 15px;
    width: 15px;
    background-color: #fcc30f;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 1px;
    right: -5px;
  }
}
