.tabs {
  display: flex;
  width: 100vw;
  overflow: auto;
  white-space: nowrap;
  .tab {
    width: 100%;
    height: 65px;
    opacity: 0.5;
    color: #bcbec0;
    font-size: 12px;
    letter-spacing: 0.14px;
    line-height: 24px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 15px;

    img {
      height: 25px;
    }

    &.selected {
      // font-weight: bold;
      color: #000000;
      opacity: 1;
      border-bottom: solid 3px #000000;
    }
    &:hover {
      opacity: 0.7;
    }
  }
}

@media only screen and (min-width: 64em) {
  .tabs {
    .tab {
      font-size: 15px;
      height: 75px;

      img {
        height: 40px;
      }
    }
  }
}
