.empty-state {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 350px);
  padding-top: 0;
  .empty-state_img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}

@media only screen and (min-width: 64em) {
  .empty-state {
    width: 100%;
    height: calc(100vh - 450px);
    padding-top: 0;
    .empty-state_img {
      width: 30%;
      height: auto;
    }
  }
}
